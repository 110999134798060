export const INIT_IFRAME = "INIT_IFRAME";
export const INIT_IFRAME_PREVIEW = "INIT_IFRAME_PREVIEW";
export const RESIZE = "RESIZE";

export const SET_END_USER_ATTRIBUTE = "SET_END_USER_ATTRIBUTE";

export const SET_DEBUG_MODE = "SET_DEBUG_MODE";

export const DOMAIN_NOT_ALLOWED = "DOMAIN_NOT_ALLOWED";

export const SET_THEME = "SET_THEME";
export const CONFIG_UPDATED = "CONFIG_UPDATED";

export const OPEN_WIDGET = "OPEN_WIDGET";
export const CLOSE_WIDGET = "CLOSE_WIDGET";
export const RELOAD_WIDGET = "RELOAD_WIDGET";

export const WIDGET_LOADED = "WIDGET_LOADED";
