/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as EventTypes from "../shared/eventTypes";
import { UnmountedError, DomainVerificationError } from "../shared/errors";

import { ChatWidgetConfig, WindowDimensions } from "@/shared/eventPayloads";
import { mobileBreakpoint } from "@/shared/helpers";

const WIDGET_WRAPPER_ID = "superagent-chat-widget-container";
const IFRAME_ID = "superagent-chat-widget-iframe-element";

const WRAPPER_MINIMIZED_HEIGHT = 110;
const WRAPPER_MINIMIZED_WIDTH = 110;

const WRAPPER_MAXIMIZED_HEIGHT = 800;
const WRAPPER_MAXIMIZED_WIDTH = 400;

(window as any).global = window;

class SuperAgentChatWidget {
  private iframe: HTMLIFrameElement | undefined;
  private widgetId: string | undefined;
  private debugMode: boolean;
  private previewMode: boolean;
  private maximized: boolean;
  private onloadFunc: () => void;
  private domainAllowed: boolean;
  private config: Record<string, any>;

  constructor(onloadFunc = function () {}) {
    this.debugMode = false;
    this.previewMode = false;
    this.onloadFunc = onloadFunc;
    this.domainAllowed = true; // optimistically assume for now
    this.maximized = false;
    this.config = {};
  }

  // PUBLIC API
  init = (widgetId: string) => {
    if (widgetId === "preview") {
      this.previewMode = true;
    } else {
      this.widgetId = widgetId;
    }

    this.initializeIframe();
    this.mountIframe();
    this.handleBootstrapDone();
  };

  setAttribute = (name: string, value: any, type = "STRING") => {
    this.ensureAllowed();
    this.ensureMounted();

    if (!this.iframe?.contentWindow) {
      throw new UnmountedError("iframe isn't mounted");
    }

    this.iframe.contentWindow.postMessage(
      {
        type: EventTypes.SET_END_USER_ATTRIBUTE,
        value: { name, value, type },
      },
      "*"
    );
  };

  debug = () => {
    this.ensureAllowed();
    this.debugMode = !this.debugMode;
    console.info(
      `[Chat] debug mode ${this.debugMode ? "enabled" : "disabled"}`
    );
    this.iframe?.contentWindow?.postMessage(
      { type: EventTypes.SET_DEBUG_MODE, value: this.debugMode },
      "*"
    );
  };

  setConfig = (key: string, value: any) => {
    this.config[key] = value;
    this.iframe?.contentWindow?.postMessage(
      { type: EventTypes.CONFIG_UPDATED, value: this.config },
      "*"
    );
  };

  getConfig = () => {
    return this.config;
  };

  // PRIVATE METHODS
  ensureMounted = () => {
    if (!document.getElementById(IFRAME_ID)) {
      throw new UnmountedError(
        "superAgentWidget.init needs to be called first"
      );
    }
  };

  ensureAllowed = () => {
    if (!this.domainAllowed) {
      throw new DomainVerificationError(
        `${window.location.host} is not permitted to use widget ID ${this.widgetId}`
      );
    }
  };

  receiveMessage = (event: any) => {
    if (!!event && !!event.data && !!event.data.type) {
      switch (event.data.type) {
        case EventTypes.DOMAIN_NOT_ALLOWED:
          this.handleDomainNotAllowed();
          break;
        case EventTypes.OPEN_WIDGET:
          this.maximizeWrapper();
          break;
        case EventTypes.CLOSE_WIDGET:
          this.minimizeWrapper();
          break;
        case EventTypes.WIDGET_LOADED:
          this.onLoaded();
          break;
        case EventTypes.RELOAD_WIDGET: {
          const version = event.data.version;
          this.reloadWidget(version);
          break;
        }
      }
    }
  };

  handleBootstrapDone = () => {
    const superagentWidgetApi = window.superAgentWidget;
    superagentWidgetApi.setAttribute = this.setAttribute;
    superagentWidgetApi.debug = this.debug;
    superagentWidgetApi.setConfig = this.setConfig;
    superagentWidgetApi.getConfig = this.getConfig;
    superagentWidgetApi._c = window.superAgentWidget._c;

    this.runPriorCalls();
    window.superAgentWidget = superagentWidgetApi;
  };

  handleDomainNotAllowed = () => {
    this.domainAllowed = false;
  };

  initializeIframe = () => {
    if (!process.env.VUE_APP_IFRAME_URL) {
      throw new Error("Iframe URL missing from config");
    }

    const styles = `@media only screen and (max-width: ${mobileBreakpoint}px) {
      body.superagent-chat-active { 
        position: fixed;
        height: 100%;
        width: 100%;
        inset: 0px;
        overflow-y: hidden;
      } 
    }`;

    const css = document.createElement("style");
    css.appendChild(document.createTextNode(styles));

    document.getElementsByTagName("head")[0].appendChild(css);

    if (!document.getElementById(IFRAME_ID)) {
      const iframe = document.createElement("iframe");
      //iframe.allow = "microphone; camera; payment; encrypted-media";

      iframe.onload = () => {
        if (!this.iframe?.contentWindow) {
          throw new UnmountedError(
            "superAgentWidget.init needs to be called first"
          );
        }

        if (this.widgetId) {
          this.iframe?.contentWindow?.postMessage(
            {
              type: EventTypes.INIT_IFRAME,
              value: {
                widgetId: this.widgetId,
                topHost: window.location.host,
                dimensions: {
                  width: window.innerWidth,
                  height: window.innerHeight,
                },
              } as ChatWidgetConfig,
            },
            "*"
          );
        } else if (this.previewMode) {
          this.iframe?.contentWindow?.postMessage(
            {
              type: EventTypes.INIT_IFRAME_PREVIEW,
              value: {
                dimensions: {
                  width: window.innerWidth,
                  height: window.innerHeight,
                },
              } as ChatWidgetConfig,
            },
            "*"
          );
        } else {
          console.error(
            "Tried to initialize iframe, but was not in preview mode and neither had a widget ID"
          );
        }
      };
      iframe.src = process.env.VUE_APP_IFRAME_URL;
      iframe.id = IFRAME_ID;
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.margin = "0";
      iframe.style.padding = "0";
      iframe.style.border = "none";
      iframe.style.borderWidth = "0px";

      this.iframe = iframe;
    }
  };

  runPriorCalls = () => {
    const allowedCalls = [
      /*'init','identify', */ "setAttribute",
      "debug",
      "setConfig",
      "getConfig",
    ];
    const priorCalls =
      window.superAgentWidget &&
      window.superAgentWidget._c &&
      typeof window.superAgentWidget._c === "object"
        ? window.superAgentWidget._c
        : [];

    // @ts-ignore
    priorCalls.forEach(([method, args]) => {
      if (allowedCalls.includes(method)) {
        // console.log("prior call", method);
        // @ts-ignore
        this[method].apply(this, args);
      }
    });
    this.onloadFunc.call(window.superAgentWidget);
  };

  setWrapperDimensions = () => {
    const wrapper = document.getElementById(WIDGET_WRAPPER_ID);

    if (!wrapper) {
      console.warn("Wrapper not found:", WIDGET_WRAPPER_ID);
      return;
    }

    if (!this.maximized) {
      wrapper.style.width = WRAPPER_MINIMIZED_WIDTH + "px";
      wrapper.style.height = WRAPPER_MINIMIZED_HEIGHT + "px";
      return;
    }

    let height = WRAPPER_MAXIMIZED_HEIGHT;
    let width = WRAPPER_MAXIMIZED_WIDTH;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Full width on mobile
    if (windowWidth <= mobileBreakpoint) {
      wrapper.style.width = "100%";
      wrapper.style.height = "100%";
      return;
    }

    if (height > windowHeight) {
      height = windowHeight;
    }

    if (width > windowWidth - 20) {
      width = windowWidth - 20;
    }

    wrapper.style.width = width + "px";
    wrapper.style.height = height + "px";
  };

  maximizeWrapper = () => {
    this.maximized = true;
    this.setWrapperDimensions();

    if (!document.body) {
      console.warn("document body not found");
      return;
    }

    document.body.classList.add("superagent-chat-active");
  };

  minimizeWrapper = () => {
    this.maximized = false;
    this.setWrapperDimensions();

    if (!document.body) {
      console.warn("document body not found");
      return;
    }

    document.body.classList.remove("superagent-chat-active");
  };

  reloadWidget = (newVersion: string) => {
    if (this.iframe) {
      this.iframe.src = process.env.VUE_APP_IFRAME_URL + `?v=${newVersion}`;
    } else {
      console.warn("iframe was undefined");
    }
  };

  onResize = () => {
    this.setWrapperDimensions();

    this.iframe?.contentWindow?.postMessage(
      {
        type: EventTypes.RESIZE,
        value: {
          width: window.innerWidth,
          height: window.innerHeight,
        } as WindowDimensions,
      },
      "*"
    );
  };

  onLoaded = () => {
    const onCallbacks = window.superAgentWidget._c.filter(
      (call: any) => call[0] === "on"
    );

    if (onCallbacks.length) {
      onCallbacks.forEach((callback: any) => {
        if (
          Array.isArray(callback) &&
          callback[1][0] === "loaded" &&
          typeof callback[1][1] === "function"
        ) {
          callback[1][1]();
        }
      });
    }

    this.iframe?.contentWindow?.postMessage(
      { type: EventTypes.CONFIG_UPDATED, value: this.config },
      "*"
    );
  };

  mountIframe = () => {
    if (document.getElementById(IFRAME_ID)) {
      console.log("mountIframe was called, but iframe was already mounted");
      return;
    }

    window.addEventListener("message", this.receiveMessage);
    window.addEventListener("resize", this.onResize);

    const wrapper = document.createElement("div");
    wrapper.id = WIDGET_WRAPPER_ID;
    wrapper.style.zIndex = Number.MAX_SAFE_INTEGER.toString();
    wrapper.style.width = WRAPPER_MINIMIZED_WIDTH + "px";
    wrapper.style.height = WRAPPER_MINIMIZED_HEIGHT + "px";
    wrapper.style.bottom = "0";
    wrapper.style.right = "0";
    wrapper.style.position = "fixed";
    wrapper.style.border = "0";

    if (!this.iframe) {
      throw new UnmountedError("iframe is not mounted");
    }

    wrapper.appendChild(this.iframe);
    document.body.appendChild(wrapper);
  };
}

export default ((window: Window) => {
  const onloadFunc =
    window.superAgentWidget &&
    window.superAgentWidget.onload &&
    typeof window.superAgentWidget.onload === "function"
      ? window.superAgentWidget.onload
      : function () {};
  // @ts-ignore
  const initCall = window.superAgentWidget._c.find(
    (call: any) => call[0] === "init"
  );

  /* eslint-ignore @typescript-eslint/no-empty-function */
  const widgetApi = () => {};
  const widget = new SuperAgentChatWidget(onloadFunc);

  widgetApi.init = widget.init;

  // @ts-ignore
  widgetApi[initCall[0]].apply(widgetApi, initCall[1]);
  // @ts-ignore
})(global);
