export class UnmountedError extends Error {
  constructor(message = "", ...args: any) {
    super(message, ...args);
    this.name = "UnmountedError";
    this.message = message;
  }
}

export class ResponseError extends Error {
  public data: Record<string, unknown>;

  constructor(message = "", data = {}, ...args: any) {
    super(message, ...args);
    this.name = "ResponseError";
    this.message = message;
    this.data = data;
  }
}

export class DomainVerificationError extends Error {
  constructor(message = "", ...args: any) {
    super(message, ...args);
    this.name = "DomainVerificationError";
    this.message = message;
  }
}
